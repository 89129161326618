@import './fonts.css';

html,
#root {
  width: 100%;
  min-height: 100%;
  height: 100%;
}

body {
  height: 100%;
  padding: 0;
  overflow-y: scroll;
  margin: 0;
  background-color:  #F5F5F5;
  font-family: 'Inter';
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: auto;
  -webkit-tap-highlight-color: transparent;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}